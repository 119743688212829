































































































































































@use "sass:math";
@import '@design';

.menu-section {
  position: relative;
  transition: background-color 0.2s ease, transform 0.2s ease;

  margin-bottom: 2.5em;
  &.subsection {
    margin-top: 50px;
    margin-bottom: 0;
  }
  &:not(.subsection):not(.callout) {
    padding-top: 0.5em;
  }

  &.isDirty {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 5px;
      content: '';
      background: aquamarine;
      transform: translateY(-100%);
    }
  }
  &.callout {
    padding-top: 1.3em;
    padding-bottom: 1.3em;
    margin-right: auto;
    margin-left: auto;
    border: 2px solid #979797;
    &.has-title .menu-section--items {
      margin: 1em 0 0;
    }
  }

  &.hovering {
    &.edit-ui--on {
      background-color: rgba($color-db-teal, 0.5);
    }
    &.layout-ui--off {
      background-color: rgba($color-db-green, 0.1);
    }
  }
  &.selected {
    background-color: rgba($color-db-green, 0.1);
  }
  &.delete-hover {
    background: rgba(red, 0.075) !important;
  }
  .drag-handle--wrap {
    position: absolute;
    top: 10px;
    right: 0;
    min-width: 20px;
    height: 20px;
    transform: translateY(-5px);
    .drag-handle {
      font-size: 18px;
      cursor: grab;
    }
  }
} // .menu-section

.menu-section--title {
  margin-bottom: 0;
  font-size: 2.4em;
  line-height: 1.1;
  font-weight: bold;
  font-style: italic;
  &.placeholder {
    position: relative;
    z-index: 10;
    font-style: italic;
    color: darken(white, 25%) !important;
  }
}

.menu-section--subtitle .resto-editable {
  font-size: 0.95em;
  font-weight: 400 !important;
}

.menu-section--section-columns {
  font-size: 0.95em;
  text-align: right;
  margin-bottom: 0.5em;
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 0px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 2s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0.1;
  transform: translateY(100%);
}
